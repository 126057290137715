import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import sr from '@utils/sr';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';

const StyledSkillsSection = styled.div`
  margin-top: 2rem;

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    margin: 0 32px;

    &:hover,
    &:focus {
      outline: 0;

      .img {
        -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
        filter: grayscale(0);
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }
  }
;`

const imageSelector = imageName => {
  switch (imageName) {
    case 'C++':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/C++.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="C++"
        />
      );
    case 'C#':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/C#.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="C#"
        />
      );
    case 'Java':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Java.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Java"
        />
      );
    case 'Python':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Python.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Python"
        />
      );
    case 'Dart':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/dart.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Dart"
        />
      );
    case 'SQL':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/SQL.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="SQL"
        />
      );
    case 'HTML':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Html.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="HTML"
        />
      );
    case 'CSS':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/css.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="CSS"
        />
      );
      case 'Tailwind CSS':
        return (
          <StaticImage
            className="img"
            src="../../../public/skills/Tailwind-css.png"
            height={64}
            quality={95}
            objectFit="contain"
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Tailwind CSS"
          />
        );
    case 'Bootstrap':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/bootstrap.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Bootstrap"
        />
      );
    case 'JavaScript':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Javascript.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="JavaScript"
        />
      );
    case 'Node.js':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Node-js.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="NodeJS"
        />
      );
    case 'React.js':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/React-js.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="ReactJS"
        />
      );
    case 'Express.js':
      return (
        <StaticImage 
          className="img"
          src="../../../public/skills/express-js.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="ExpressJS"
        />
      );
    case 'Spring Boot':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/SpringBoot.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Spring Boot"
        />
      );
    case 'Flutter':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/flutter.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Flutter"
        />
      );
    case 'Git':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/git.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Git"
        />
      );
    case 'GitHub':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/github.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="GitHub"
        />
      );
    case 'VS Code':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/VS-code.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="VS Code"
        />
      );
    case 'Android Studio':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/android-studio.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Android Studio"
        />
      );
      case 'Eclipse':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/eclipse.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Eclipse"
        />
      );
    case 'Azure':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Azure.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Azure"
        />
      );
    case 'Trello':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Trello.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Trello"
        />
      );
    case 'Figma':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Figma.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Figma"
        />
      );
    case 'Photoshop':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/Photoshop.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Photoshop"
        />
      );
    case 'Material-UI':
      return (
        <StaticImage
          className="img"
          src="../../../public/skills/material-ui.png"
          height={64}
          quality={95}
          objectFit="contain"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Material-UI"
        />
      );
    default:
      return null; // Return null or a default image if preferred
  }
};

const Skills = () => {
  const revealContainer = useRef(null);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealServices = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealServices.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const skills = [
    'C++',
    'C#',
    'Java',
    'Python',
    'Dart',
    'SQL',
    'HTML',
    'CSS',
    'Tailwind CSS',
    'Bootstrap',
    'JavaScript',
    'Node.js',
    'React.js',
    'Express.js',
    'Spring Boot',
    'Flutter',
    'Git',
    'GitHub',
    'VS Code',
    'Android Studio',
    'Eclipse',
    'Azure',
    'Trello',
    'Figma',
    'Photoshop',
    'Material-UI',
  ];

  return (
    <StyledSkillsSection>
      <Marquee speed={100} autoFill="true" pauseOnHover="true" gradient="true" gradientColor="#0a192f">
        {skills.map((skill, index) => (
          <div key={index} className="wrapper">
            {imageSelector(skill)}
          </div>
        ))}
      </Marquee>
    </StyledSkillsSection>
  );
};

export default Skills;
